<template>
    <div class="search">
        <div class="search_mask" v-if="isDisabled" @click="onClick"></div>
        <nut-icon type="search" color="#A2A2A2" size="13px"></nut-icon>
        <form
                @submit.prevent="formSubmit"
                @click="onClick"
                action="javascript:return true"
        >
            <input
                    type="search"
                    :value="value"
                    @input="$emit('input', $event.target.value)"
                    @keydown="search($event)"
                    :placeholder="placeholder"
            />
            <img
                    v-show="hasValue"
                    @click.stop="close"
                    :src="require('@STATIC/image/icons/closeFill-gray.png')"
            />
        </form>
    </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: '请输入您想需要的商品或品牌名称',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      jumpUrl: {
        type: String,
        default: '',
      },
    },
    computed: {
      hasValue () {
        if (this.$utils.rule.empty(this.$props.value)) {
          return false
        }
        return true
      },
    },
    methods: {
      formSubmit () {
        return false
      },
      onClick () {
        if (this.$props.isDisabled) {
          this.$emit('onClick')
        }
      },
      close () {
        this.$emit('input', '')
        this.$emit('clear')
      },
      search (e) {
        if (e.keyCode == 13) {
          this.$emit('search')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
    .search {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #efefef;
        border-radius: 18px;
        padding: 0 19px;
        & > form {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            & > input::placeholder {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #a2a2a2;
            }
            & > input {
                box-sizing: border-box;
                padding-right: 10px;
                margin-top: 2px;
                flex-grow: 1;
                margin-left: 4.5px;
                border: 0;
                outline: none;
                background-color: transparent;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333;
                &::-ms-clear {
                    display: none !important;
                }
                &::-webkit-search-cancel-button {
                    display: none !important;
                }
            }
            & > img {
                flex-shrink: 0;
                width: 12px;
                height: 12px;
            }
        }
        @include e(mask) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
           opacity: 0.1;
        }
    }
</style>
